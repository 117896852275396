.card_container {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

    border-radius: 10px;
    background: #fff;

    /* DS 1 */
    box-shadow:
        0px 8px 32px 0px rgba(17, 17, 26, 0.05),
        0px 4px 16px 0px rgba(17, 17, 26, 0.05);
    cursor: pointer;
}

.card_title {
    color: var(--Black-1, #21233D);
    text-align: start;

    /* Headline/Headline Small */
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}

.sale_price {
    color: #21233D;
    text-align: center;

    /* Headline/Headline Small */
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}
.price {
    color: var(--Grey-5, #e0e0e0);
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    text-decoration: line-through;
}

.card_list {
    display: grid;
    gap: 15px;

}

.teacher_avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
}