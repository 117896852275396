.container {
    position: relative;
}

.label {
    position: absolute;
    top: -70%;
    left: 0%;
    font-weight: 500;
    color: #ffffff;
}

.select {
    width: 230px;
    height: 48px;
    outline: 0;
    padding: 10px 16px 10px 16px;
    border-radius: 4px;
    border: 1px;
    justify-content: stretch;

    background: linear-gradient(0deg, #ffffff, #ffffff);
    border: 1px solid rgba(255, 255, 255, 1);
    cursor: pointer;
}
