.breadcrumb_custom {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}
.breadcrumb_item,
.breadcrumb_item_active {
    font-size: 14px;
    color: white !important;
}
.breadcrumb_item_active {
    color: rgba(255, 255, 255, 0.65) !important;
}