.container {
    position: relative;
}

.label {
    font-weight: 500;
    color: #ffffff;
}

.select {
    width: 100%;
    height: 48px;
    outline: 0;
    padding: 10px 16px 10px 16px;
    border-radius: 4px;
    border: 1px;
    justify-content: stretch;
    background-color: transparent;

    color: rgba(255, 255, 255, 1);


    border: 1px solid rgba(255, 255, 255, 1);
    cursor: pointer;
}
