.session1_container {
    padding-top: 50px;
    padding-bottom: 150px;
    background: linear-gradient(180deg, #00afd6 0%, #1870f5 100%);
}
.session1_subcontainer {
    display: grid;
    gap: 25px;
}
.breadcrumb_custom {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}
.breadcrumb_item,
.breadcrumb_item_active {
    font-size: 14px;
    color: white !important;
}
.breadcrumb_item_active {
    color: rgba(255, 255, 255, 0.65) !important;
}

.session1_title {
    font-family: Roboto;
    font-size: 54px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -0.25px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.session1_text {
    color: rgba(255, 255, 255, 1);
}

.session1_best_seller_container {
    display: flex;
    padding: 8px 16px 8px 16px;
    border-radius: 99px;
    border: 1px;
    gap: 10px;
    background: linear-gradient(
            117.54deg,
            rgba(255, 255, 255, 0.5) -19.85%,
            rgba(235, 235, 235, 0.367354) 4.2%,
            rgba(224, 224, 224, 0.287504) 13.88%,
            rgba(212, 212, 212, 0.21131) 27.98%,
            rgba(207, 207, 207, 0.21) 37.8%,
            rgba(202, 202, 202, 0.143432) 44.38%,
            rgba(200, 200, 200, 0.14) 50.54%,
            rgba(196, 196, 196, 0.32) 60.21%
        ),
        linear-gradient(
            126.88deg,
            rgba(255, 255, 255, 0.64) 16.19%,
            rgba(255, 255, 255, 0.27) 31.11%,
            rgba(255, 255, 255, 0.09) 42.86%,
            rgba(255, 255, 255, 0.45) 64.32%
        );

    width: fit-content;
    color: #fff;
    border: 1px solid;

    /* border-image-source: linear-gradient(
        126.88deg,
        rgba(255, 255, 255, 0.64) 16.19%,
        rgba(255, 255, 255, 0.27) 31.11%,
        rgba(255, 255, 255, 0.09) 42.86%,
        rgba(255, 255, 255, 0.45) 64.32%
    ); */
    border: 1px solid white;
}

/* .side_bar {
    display: block;
} */
@media (max-width: 768px) {
    .side_bar {
        display: none;
    }
}

.side_bar_fixed {
    position: fixed;
    top: 7%;
    right: 7%;
    bottom: 2%;
}

.side_bar_absolute {
    position: absolute;
    bottom: -3%;
    right: 7%;
}
