.root_container {
    background: rgba(255, 255, 255, 1);

    display: grid;
    padding: 15px;
    border-radius: 10px;
    gap: 15px;

    box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05);

    box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.05);
}
.title {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    color: #21233D;
}
.divide {
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background: rgba(229, 229, 229, 1);
}

.left_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 30px 15px;
    border-radius: 10px;
    gap: 15px;

    background: rgba(255, 188, 64, 0.1);
}

.left_title {
    font-size: 48px;
    font-weight: 900;
    line-height: 40px;
    color: rgba(0, 0, 0, 1);
}

.right_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.right_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .right_text {
        font-weight: 700;
        width: 50px;
    }
}
