.root_container {
    background: rgba(255, 255, 255, 1);

    display: grid;
    padding: 15px;
    border-radius: 10px;
    gap: 15px;

    box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05);

    box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.05);
}
.title {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    color: #21233D;
}
.divide {
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background: rgba(229, 229, 229, 1);
}

.teacher_avatar {
    border-radius: 50%;
    width: 210px;
    height: 210px;
}

.icon {
    cursor: pointer;
}