

.container {
    margin-top: 50px;
    .heading_container {
        display: inline;
        background: #f5fcfe;
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 50px;
        .title_heading {
            font-size: 54px;
            font-weight: 700;
            line-height: 64px;
            letter-spacing: -0.25px;
        }
    
        .video {
            width: 100%;
            border-radius: 10px;
        }
    }
    .session_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        gap: 30px;

        .subsession {
            width: 100%;
            display: flex;
            flex-direction: column;
            /* gap: 10px; */
            padding-top: 15px;
            padding-bottom: 15px;
            align-items: center;

            .group {
                padding: 15px;
                border-radius: 10px;
                gap: 15px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05);

                box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.05);

                .ul {
                    list-style-image: url("/public/li-bullet-custom.svg");
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }
        }

        .title {
            font-size: 40px;
            font-weight: 500;
            line-height: 48px;
            letter-spacing: 0em;
            color: #21233D;
        }
    }

    .teacher_container {

        padding-top: 50px;
        padding-bottom: 50px;
        .title {
            text-align: center;
            font-size: 40px;
            font-weight: 500;
            line-height: 48px;
            letter-spacing: 0em;
            color: #21233D;
        }
        .avatar {
            border-radius: 50%;
        }

        .name {
            font-size: 22px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0em;
            color: #21233D;
        }

        .major {
            font-weight: 500;
        }

        .group {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content:flex-start;
            gap: 10px;
        }
    }
}
