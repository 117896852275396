.container {
    padding: 15px;
    border-radius: 10px;
    gap: 15px;

    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05);

    box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.05);
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.014em;
    }
    .text {

    }
}
