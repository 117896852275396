.pagination {
  display: flex;
  list-style: none;
  padding: 0;

  justify-content: center;
}

.pagination li {
  /* width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 4px;
  gap: 10px;

  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center; */
}

/* .pagination li a {
  text-decoration: none;
  display: block;
  padding: 10px;
  border: 1px solid #ddd;
  background: #fff;
} */

/* .pagination li a.active {
  background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);

  color: white;
  border-color: #00afd6;
} */

.pagination li a.disabled {
  color: grey;
  border-color: grey;
}

.pagination li > .page-link {
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 4px;
  gap: 10px;

  color: #21233D;

  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;

  box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05);

  box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.05);
}

.pagination li.active > .page-link {
  color: white;
  background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
}
