.container {
    display: grid;
    gap: 30px;

    .title {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        color: rgba(0, 0, 0, 1);
    }
}

.button_outline_sky {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 5px;

    border-radius: 4px;
    border: 1px solid #71b1e1;
    background: #fff;

    color: #71b1e1;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* @media (max-width: 768px) {
    .container {
        padding-left: 10px;
    }
    
} */