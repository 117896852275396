body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 176.471%;
  color: #21233D;
}

p,
div,
li {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 176.471%;
  color: #21233D;
}
a {
  color: #21233D;
  margin-top: 0!important;
  text-decoration: none!important;
}