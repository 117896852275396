.root_container {
    margin-top: 100px;
    margin-bottom: 100px;
    .title {
        font-size: 40px;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: 0em;
    }
    .container {
        .title {
            display: flex;
            justify-content: space-between;

            background: rgba(255, 255, 255, 1);

            padding: 30px 28px 30px 28px;
            border-radius: 20px 20px 0px 0px;

            box-shadow: 0px 25px 50px 0px rgba(22, 25, 79, 0.05);

            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 0em;
            color: #21233D;

            cursor: pointer;
        }

        .title.active {
            background: rgba(207, 226, 255, 1);
        }

        .content {
            padding: 10px 28px 10px 28px;
            border-radius: 0px 0px 20px 20px;

            color: #21233D;
            box-shadow: 0px 25px 50px 0px rgba(22, 25, 79, 0.05);
        }
    }
}
