h4 {
  color: #21233D;
}

.primary-color {
  background: linear-gradient(94deg, #1870f5 9.29%, #00afd6 103.5%);
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.button-gradient {
  width: 100%;
  padding: 10px 50px 10px 50px;
  border-radius: 10px;
  background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
  border: 0;

  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.button-outline {
  width: 100%;
  padding: 10px 50px 10px 50px;
  border-radius: 10px;
  background: white;
  border: 1px solid rgba(218, 218, 218, 1);

  font-weight: 500;

}

.divide {
  width: 100%;
  height: 1px;
  background: rgba(229, 229, 229, 1);
}

.disabled-item {
  backdrop-filter: blur(16px);
  pointer-events: none;
  opacity: 0.35;
}

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
  /* display: none; */
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
  border-radius: 10px;
}