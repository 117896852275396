.root_container {
    /* position: fixed;
    top: 10%;
    right: 7%; */
    width: 399px;
    padding: 2px;
    border-radius: 10px;
    gap: 10px;
    border: 0;
    background: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
}
.root_wraper {
    display: grid;
    gap: 10px;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 10px;
    /* margin: 2px; */
    padding: 20px;

    max-height: 90vh;
    overflow-y: scroll;
}

.side_bar_course_info {
    span {
        font-size: 14px;
    }
}

.course_info_collapse {
    height: 150px;
    overflow: hidden;
}

.course_info_expand {
    height: 100%;
}
.video {
    width: 100%;
    border-radius: 10px;
}

.sale_price {
    color: var(--Grey-2, #4f4f4f);
    text-align: center;

    /* Headline/Headline Small */
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}
.price {
    color: var(--Grey-5, #e0e0e0);
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    text-decoration: line-through;
}

.side_bar_divide {
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background: rgba(229, 229, 229, 1);
}
