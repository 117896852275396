.home_container {
    display: grid;
    gap: 40px
}


.banner_right_container {
    display: grid;
    gap: 25px;
}

.button_primary {
    display: flex;
    padding: 15px 50px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 10px;
    background: var(--button-2, linear-gradient(180deg, #1870f5 0%, #00afd6 100%));
    border: 0;

    color: var(--Black-White, var(--White, #fff));
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.button_outline {
    display: flex;
    padding: 15px 50px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 0;
    border: 1px solid var(--button-2, #1870f5);

    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: var(--button-2, linear-gradient(180deg, #1870f5 0%, #00afd6 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.banner_right_title {
    color: var(--Primary, #21233d);

    /* Display/Display Large */
    font-family: Roboto;
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px; /* 118.519% */
    letter-spacing: -0.25px;
}

.banner_left_container {
    position: relative;
}

.banner_left_card_container {
    position: absolute;
    right: 0;
    bottom: 5%;
    /* display: inline-flex;
    padding: 15px;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;

    border-radius: 10px;
    background: var(--button-2, linear-gradient(180deg, #1870f5 0%, #00afd6 100%));

    box-shadow:
        0px 8px 32px 0px rgba(17, 17, 26, 0.05),
        0px 4px 16px 0px rgba(17, 17, 26, 0.05); */
}

.session2_container {
}
.session2_header,
.session3_header {
    display: grid;
    gap: 15px;
}

.session2_header_item2,
.session3_header_item2 {
    color: var(--Primary, #21233d);
    text-align: center;

    /* Display/Display Small */
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 120% */
}

.button_outline_sky {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 5px;

    border-radius: 4px;
    border: 1px solid #71b1e1;
    background: #fff;

    color: #71b1e1;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.session3_card_container {
    height: 100%;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    text-align: center;
    border-radius: 20px;
    /* DS 1 */
    box-shadow:
        0px 8px 32px 0px rgba(17, 17, 26, 0.05),
        0px 4px 16px 0px rgba(17, 17, 26, 0.05);
}

.session3_card1 {
    background: linear-gradient(180deg, #2bc98f 0%, #2fc8d8 100%);
}

.session3_card2 {
    background: linear-gradient(180deg, #ff6c2c 0%, #ff9a28 100%);
}

.session3_card3 {
    background: linear-gradient(180deg, #41b6ff 0%, #6f65ff 100%);
}

.session3_card4 {
    background: linear-gradient(180deg, #1870F5 0%, #00AFD6 100%);
}

.session3_card_title {
    color: var(--White, #fff);
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
}

.session3_card_text {
    color: var(--Black-White, var(--White, #fff));
    text-align: center;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 176.471% */
}
