
.container {
    background: rgba(248, 248, 255, 1);
    padding-top: 50px;
    padding-bottom: 50px;
    .row {
        .left {
            position: absolute;
            right: 15px;
            bottom: 20px;
        }
        .title {
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
            letter-spacing: 0em;
        }
    }
}


