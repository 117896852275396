.root_container {
    background: rgba(255, 255, 255, 1);

    display: grid;
    padding: 15px;
    border-radius: 10px;
    gap: 15px;

    box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05);

    box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.05);
}
.title {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    color: #21233D;
}
.divide {
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background: rgba(229, 229, 229, 1);
}

.expand {
}

.collapse {
}

.title_toggle {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}

.sub_title_toggle {
    padding: 6px 10px 6px 10px;
    border-radius: 4px;
    gap: 10px;
    color: inherit;
    background-color: rgba(249, 249, 251, 1);
    
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
}

.collapse_header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.collapsed {
}
