.container {
    display: grid;
    align-items: center;
    background: rgba(255, 255, 255, 1);
    width: 117px;
    padding: 10px 20px;
    border-radius: 99px;
    gap: 5px;
    cursor: pointer;
}
.text {
    font-weight: 500;
}
