.footer-title {
    font-weight: 700;
    font-size: 24px;
    line-height: normal;
    letter-spacing: -0.058px;
    /* color: white; */
}

.footer-ul {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    gap: 20px;
    color: #21233D;
}
.footer-ul li a {
    cursor: pointer;
    color: #21233D;
    font-size: 17px;
    margin-top: 0;
    text-decoration: none;
}

.footer-ul li:hover {
    text-decoration: underline;
}

.footer-container {
    /* margin-top: 50px; */
    border-top: 2px solid rgba(24, 112, 245, 1);
    background-color: white;
    padding: 40px 10px 50px 10px;
    color: #21233D;
}

.footer-introduce {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    align-self: stretch;
}

.footer-info {
    align-items: start;
}

.footer-social {
    cursor: pointer;
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .footer-info {
        align-items: center;
    }
    .footer-ul {
        text-align: center;
        gap: 10px;
    }

    .footer-title {
        text-align: center;
    }
    .footer-info-child {
        margin-top: 30px;
        text-align: center;
    }
    .footer-social {
        justify-content: center;
    }
}

/* Medium devices (tablets, 992px and up) */
@media (max-width: 992px) {
    .footer-ul {
        gap: 10px;
    }
}
