.container {
    display: flex;
    justify-content: center;
    padding: 4px;
    border-radius: 99px;
    background: rgba(255, 255, 255, 0.12);
    width: fit-content;
}

.item {
    padding: 10px 16px 10px 16px!important;
    border-radius: 99px;
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    gap: 5px;
    /* gap: 5px; */
    cursor: pointer;
}

.active {
    background-color: white!important;
}
