.container {
    display: flex;
    width: fit-content;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 99px;
    background: rgba(0, 175, 214, 0.12);

    color: #00afd6;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 176.471% */
    text-transform: uppercase;
}