.card_container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 15px;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: flex-start; */
    gap: 15px;
    align-items: center;

    border-radius: 10px;
    background: #fff;

    /* DS 1 */
    box-shadow:
        0px 8px 32px 0px rgba(17, 17, 26, 0.05),
        0px 4px 16px 0px rgba(17, 17, 26, 0.05);
}

.card_title {
    color: #21233D;
    text-align: center;

    /* Headline/Headline Small */
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px; /* 133.333% */
}

.role {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;

    color: linear-gradient(180deg, #1870f5 0%, #00afd6 100%);
}

.location {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;

    color: #21233D;
}

.avatar {
    border-radius: 10px;
}