
.breadcrumb-item {
    font-size: 14px!important;
    color: white!important;
}

.breadcrumb-item.active {
    color: rgba(255, 255, 255, 0.65)!important;
}

