.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f7f7f7;
  }
  
  .not-found-content {
    text-align: center;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 4rem;
    color: #ff6347;
  }
  
  h2 {
    font-size: 2rem;
    color: #333;
  }
  
  p {
    font-size: 1.2rem;
    color: #21233D;
  }
  
  a {
    display: block;
    margin-top: 20px;
    font-size: 1.2rem;
    text-decoration: none;
    color: #007bff;
    transition: color 0.3s ease;
  }
  
  a:hover {
    color: #0056b3;
  }
  