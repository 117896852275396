.submenu_container {
    
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 45px;
    .item {
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
        color: #21233D;
    }
}

@media (max-width: 768px) {
    .submenu_container {
    
        display: grid;
        padding-top: 20px;
        padding-bottom: 20px;
        gap: 25px;
        .item {
            
        }
    }
}
