.container {
    display: flex;
    padding: 10px 16px 10px 16px;
    border-radius: 99px;
    border: 1px;
    justify-content: space-between;
    border: 1px solid rgba(255, 255, 255, 1);
    width: fit-content;
}

.input {
    border: 0;
    background: transparent;
    outline: 0;
    color: white;
}
