.hr {
    height: 1px;
    opacity: 0.5;
    background: rgba(255, 255, 255, 1);
    margin-bottom: 20px;
}
.container {
    
}
.text {
    font-weight: 500;
}
