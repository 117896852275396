.container {
    padding: 20px;
    border-radius: 10px;
    gap: 10px;
    background-color: white;
    box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05);
    box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.05);

    .title {
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        color: #21233D;
    }

    .form {
        display: grid;
        gap: 20px;
        margin-top: 20px;


        .label {
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
        }

        .select {
            width: 100%;
            padding: 10px;
            outline: 0;
            border-radius: 6px;
            border: 1px solid rgba(189, 189, 189, 1);
            color: rgba(189, 189, 189, 1);
        }

        .option {
            padding: 10px;
            font-weight: 500 !important;
            padding: 8px 12px;
            color: #21233D;
            margin: 10px;
        }
        .input {
            width: 100%;
            border: 0;
            outline: 0;
            border-bottom: 1px solid rgba(189, 189, 189, 1);
        }

        .button {
            border-radius: 99px;
        }

        ::placeholder {
            color: rgba(189, 189, 189, 1);
            font-size: 17px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
            opacity: 1; /* Firefox */
        }

        ::-ms-input-placeholder {
            /* Edge 12 -18 */
            color: rgba(189, 189, 189, 1);
            font-size: 17px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
        }
        .label {
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
        }

        .select {
            width: 100%;
            padding: 10px;
            outline: 0;
            border-radius: 6px;
            border: 1px solid rgba(189, 189, 189, 1);
            color: rgba(189, 189, 189, 1);
        }

        .option {
            padding: 10px;
            font-weight: 500 !important;
            padding: 8px 12px;
            color: #21233D;
            margin: 10px;
        }
        .input {
            width: 100%;
            border: 0;
            outline: 0;
            border-bottom: 1px solid rgba(189, 189, 189, 1);
        }

        .button {
            border-radius: 99px;
        }

        ::placeholder {
            color: rgba(189, 189, 189, 1);
            font-size: 17px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
            opacity: 1; /* Firefox */
        }

        ::-ms-input-placeholder {
            /* Edge 12 -18 */
            color: rgba(189, 189, 189, 1);
            font-size: 17px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
        }
    }
}
