.session1_container {
    padding-top: 50px;
    padding-bottom: 150px;
    background: linear-gradient(180deg, #00afd6 0%, #1870f5 100%);
}
.session1_subcontainer {
    display: grid;
    gap: 25px;
}
.breadcrumb_custom {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}
.breadcrumb_item,
.breadcrumb_item_active {
    font-size: 14px;
    color: white !important;
}
.breadcrumb_item_active {
    color: rgba(255, 255, 255, 0.65) !important;
}

.session1_title {
    font-family: Roboto;
    font-size: 54px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -0.25px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.session1_text {
    color: rgba(255, 255, 255, 1);
}

.session2_container {
    margin-top: -100px;
}

.session3_container {
    margin-top: 30px;
}