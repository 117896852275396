.banner_left_card_container {
    /* position: absolute;
    right: 0;
    bottom: 5%; */
    display: inline-flex;
    padding: 15px;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;

    border-radius: 10px;
    background: var(--button-2, linear-gradient(180deg, #1870f5 0%, #00afd6 100%));

    /* DS 1 */
    box-shadow:
        0px 8px 32px 0px rgba(17, 17, 26, 0.05),
        0px 4px 16px 0px rgba(17, 17, 26, 0.05);
}