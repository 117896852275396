.container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 99px;
    padding: 6px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05);

box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.05);

}

.btn_active {
    width: "fit-content";
    padding: 10px 20px 10px 20px;
    border-radius: 99px;
    gap: 10px;
    background: linear-gradient(180deg, #1870F5 0%, #00AFD6 100%);
    border: 0;
    color: white;
    font-weight: 500;
}

.btn {
    width: "fit-content";
    padding: 10px 20px 10px 20px;
    border-radius: 99px;
    gap: 10px;
    background: rgba(245, 247, 251, 1);
    border: 0;
    color: #21233D;
    font-weight: 500;
}

.a_custom {
    color: inherit;
}