.container {
    position: relative;
    height: 48px;
}

.label {
    font-weight: 500;
    color: #ffffff;
}


